import React from 'react'
import './About.scss'
import Everything from './components/Everything/Everything'
import data from '../../data.json'

const About = () => {

  

    return (
        <div className='main_about'>
            <div className="container">

                <div className="top_about">

                    <div className="left_about">
                        <p className='nice'>- Nice To Meet You!</p>
                        <div className="name">{data.name}</div>
                        <p className='nice_about'>{data.titledes}</p>
                        <button className="primary_btn">Got a project?</button>
                    </div>
                    <div className="right_about">
                        {data.maindes}
                        <div className="personal_main">
                            <div className="personal_content">
                                <div className="person_title">
                                    AGE
                                </div>
                                <div className="person_answer">
                                    {data.age}
                                </div>
                            </div>
                            <div className="personal_content">
                                <div className="person_title">
                                    BORN IN
                                </div>
                                <div className="person_answer">
                                    {data.bornin}
                                </div>
                            </div>
                            <div className="personal_content">
                                <div className="person_title">
                                    MAIL
                                </div>
                                <div className="person_answer">
                                    {data.mail}
                                </div>
                            </div>
                            <div className="personal_content">
                                <div className="person_title">
                                    PHONE
                                </div>
                                <div className="person_answer">
                                    {data.phone}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="aboutcard_main">
                    {data.aboutcards.map((e, index) => {
                        return (
                            <div className="aboutcard" key={index} style={{ backgroundColor: e.bg }} >
                                <div className="card_title">
                                    {e.title}
                                </div>
                                <div className="card_para">
                                    {e.para}
                                </div>
                            </div>
                        )
                    })}


                </div>

            </div>
            <Everything />
        </div>
    )
}

export default About