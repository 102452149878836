import React from "react";
import "./Everything.scss";
import data from '../../../../data.json'

const Everything = () => {


  return (
    <div className="main_everything">
      <div className="container">
        <div className="exper">- EXPERIENCE</div>

        <div className="exper_title">Everything about me!</div>

        <div className="experCard_main">
          {data.experiencecard.map((e, index) => {
            return (
              <div className="expercard" key={index}>
                <div className="short">
                  <div className="job">
                    <span className="yellowColor">{e.date}</span>
                    <h3>{e.title}</h3>
                  </div>
                  <div className="place">
                    <span>{e.place}</span>
                  </div>
                </div>
                <div className="text">
                  <p>{e.para}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Everything;
