import React from "react";
import pic from "../../assets/pic.jpg";
import "./Sidebar.scss";
import { BiLogoFacebook } from "react-icons/bi";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import data from '../../data.json'

const Sidebar = () => {

  const location = useLocation()

  return (
    <div className="main_sidebar">
      <div className="sidebar_top">
        <div className="profile_pic">
          <img style={{ borderRadius: "50rem" }} src={pic} alt="Image Error" />
        </div>
        <div className="name">
          <h3>
            <span>
              {data.name}<span className="back">{data.name}</span>
            </span>
          </h3>
        </div>
      </div>

      <div className="sidebar_centre">
        <Link to='/' style={{ textDecoration: "none", color: "unset" }} >
          <div className={location.pathname === "/" ? "menu_active" : "menu"} >Home</div>
        </Link>
        <Link to='/about' style={{ textDecoration: "none", color: "unset" }} >
          <div className={location.pathname === "/about" ? "menu_active" : "menu"}>About</div>
        </Link>
        <Link to='/service' style={{ textDecoration: "none", color: "unset" }} >
          <div className={location.pathname === "/service" ? "menu_active" : "menu"}>Services</div>
        </Link>
        <Link to='/portfolio' style={{ textDecoration: "none", color: "unset" }} >
          <div className={location.pathname === "/portfolio" ? "menu_active" : "menu"}>Portfolio</div>
        </Link>
        {/* <Link to='/blogs' style={{textDecoration:"none",color:"unset"}} >
        <div className={location.pathname === "/blogs" ? "menu_active":"menu"}>Blog</div>
        </Link> */}
        <Link to='/contactus' style={{ textDecoration: "none", color: "unset" }} >
          <div className={location.pathname === "/contactus" ? "menu_active" : "menu"}>Contact</div>
        </Link>
      </div>

      <div className="sidebar_bottom">
        <div className="social_icon">
          <button className="icon_back" onClick={()=> window.open(data?.instaLink)}>
            <FaInstagram className="icons" />
          </button>
          <button className="icon_back" onClick={()=> window.open(data?.twitterLink)}>
            <FaTwitter className="icons" />
          </button>
          <button className="icon_back" onClick={()=> window.open(data?.linkdinLink)}>
            <FaLinkedinIn className="icons" />
          </button>
          <button className="icon_back" onClick={()=> window.open(data?.githubLink)}>
            <FaGithub className="icons" />
          </button>
        </div>
        <p>Copyright © 2023 Junaid Latif. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Sidebar;
